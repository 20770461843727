<template>
    <div class="box">
        <div class="box-header with-border">
            <h3>
                <previous-route-block/>

                {{ page_title }}
            </h3>
        </div>

        <div class="box-body">

            <AlertErrors :form="form" message="Ошибки валидации. Проверьте введённые данные."/>

            <div class="form-group" v-for="integration in integrations">
                <h4>
                    {{ integration.caption }}
                </h4>

                <div v-if="integration.type !== 'code'" class="input-group">
                    <div v-if="integration.type === 'link'" class="input-group-btn">
                        <a target="_blank" :href="integration.code" type="button"
                           :disabled="!integration.code"
                           class="btn btn-info btn-lg full-height">
                            <i class="fas fa-external-link-alt"></i>
                        </a>
                    </div>

                    <!--suppress HtmlFormInputWithoutLabel -->
                    <input v-model="integration.code"
                           :type="integration.type"
                           :placeholder="integration.caption"
                           class="form-control">
                    <div class="input-group-btn">
                        <button type="button"
                                title="Обновить запись"
                                class="btn btn-success"
                                v-on:click="fillForm(integration)">
                            <i class="fa fa-edit btn-lg"></i>
                        </button>
                    </div>
                </div>

                <div v-else class="mb-3">
                    <codemirror v-model="integration.code" :options="editor_config"></codemirror>

                    <button type="button"
                            title="Обновить запись"
                            style="min-width: 100px;"
                            class="btn btn-success btn-lg mt-2 pull-right"
                            v-on:click="fillForm(integration)">
                        <i class="fa fa-edit"></i>
                    </button>
                </div>
                <small class="mt-1" v-if="integration.editor">
                    Обновил <strong>{{ integration.editor.name }}</strong> {{ integration.updated_date }}
                </small>

                <br/>
            </div>
        </div>
    </div>
</template>

<!--suppress ES6CheckImport -->
<script>
    import LocaleService from "../../services/LocaleService";
    import {codemirror} from 'vue-codemirror';
    import 'codemirror/lib/codemirror.css';
    import 'codemirror/theme/monokai.css';
    import 'codemirror/addon/selection/active-line.js';
    import 'codemirror/mode/javascript/javascript.js';
    import EditorConfigCodeData from './../../assets/data/codemirror-editor-config.json';
    import Form from "vform";

    Form.axios = API.apiClient;

    export default {
        name: 'integrations',

        components: {
            AlertErrors,
            codemirror,
        },

        metaInfo() {
            return {
                title: this.page_title,
            }
        },

        data: () => ({
            page_title: 'Интеграции',

            form: new Form(),

            integration: null,

            integrations: [],

            locales: [],

            editor_config: EditorConfigCodeData,

            onProgress: true,
        }),

        async created() {
            try {
                this.locales = await LocaleService.getItems();

                await this.getIntegrations();
            } catch (error) {
                errorHandler(error);
            }

            this.onProgress = false;
        },

        computed: {
            validateData: function () {
                return this.integration && this.integration.id && this.locales.length > 0 && this.form;
            },
        },

        methods: {
            async getIntegrations() {
                try {
                    const integrations_response = await API.apiClient.get('/integrations', {
                        params: {
                            relations: ['editor'],
                        }
                    });

                    this.integrations = integrations_response.data;
                } catch (error) {
                    errorHandler(error);
                }

                this.onProgress = false;
            },

            fillForm(integration) {
                try {
                    this.resetForm();

                    this.integration = integration;
                    this.form.code = this.integration ? this.integration.code : null;

                    this.editIntegration();
                } catch (error) {
                    this.resetForm();
                    errorHandler(error);
                }
            },

            async editIntegration() {
                try {
                    if (this.validateData) {
                        await this.form.put('/integrations/edit/' + this.integration.id).then(() => {
                            showSuccess();
                            this.getIntegrations();
                        }).catch((error) => {
                            errorHandler(error);
                        });
                    }
                } catch (error) {
                    errorHandler(error);
                }

                await this.resetForm();
            },

            async resetForm() {
                this.integration = null;
                this.form.code = null;
            }
        },
    }
</script>

<style scoped>

</style>
